import BaseApiClient from './BaseApiClient';

export default class JobApiClient extends BaseApiClient {
    constructor(...args) {
        super('/job/api/v1/', ...args);
        ['listJobs', 'jobLogs', 'jobDetail', 'deleteJob', 'jobUrl', 'submitBopsJob'].forEach(it => {
            this[it] = this[it].bind(this);
        });
    }

    async listJobs() {
        const response = await super.handleGet(`${this.basepath}job`);
        super.handleResponse(response);
        return response.data;
    }

    async jobLogs(name) {
        const response = await super.handleGet(this.jobUrl(name, '/log'));
        super.handleResponse(response);
        return response.data;
    }

    async jobDetail(name) {
        const response = await super.handleGet(this.jobUrl(name));
        super.handleResponse(response);
        return response.data;
    }

    async deleteJob(name) {
        const response = await this.axios.delete(this.jobUrl(name));
        super.handleResponse(response);
        return response.data;
    }

    async fixJobs() {
        const response = await super.handlePost(`${this.basepath}fix`);
        super.handleResponse(response);
        return response.data;
    }

    async submitBopsJob(args, daemon, schedule) {
        const response = await super.handlePost(`${this.basepath}job`, {schedule, args, daemon});
        super.handleResponse(response);
        return response.data;
    }

    jobUrl(name, path = '') {
        return `${this.basepath}job/${name}${path}?product=${this.product}`;
    }
}
