import * as React from "react";
import { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { history, namespacedLocalStorage, NavBar, signInService } from "@surelync/common";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AuthApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/AuthApiClient";

const useStyles = makeStyles({
    fullHeight: {
        height: "100vh",
    },
    scrollable: {
        overflow: "hidden",
        overflowY: "auto",
        width: "100%",
    },
});

export const Layout: React.FC = ({ children }) => {
    const classes = useStyles();
    const theme = useTheme();
    const [isLoaded, setLoaded] = useState(false);
    const authApiClient = new AuthApiClient(namespacedLocalStorage);

    useEffect(() => {
        let isMount = true;
        const fetchData = async () => {
            const whoami = await authApiClient.whoAmI();
            if ((isMount && !whoami) || !whoami.party || whoami.username === "anonymous") {
                const pathname = window.location.pathname.startsWith("/surelync")
                    ? window.location.pathname.replace("/surelync", "")
                    : window.location.pathname;
                signInService.signOut();
                namespacedLocalStorage.setItem("redirect", pathname);
                history.push("/login");
            } else {
                signInService.signIn(whoami);
                try {
                    const response = await authApiClient.getUser(whoami.username);
                    signInService.setUser(response.data);
                } catch (error) {
                    console.error(error);
                    // parse email and display First/Last name at the header.
                    signInService.setUser({
                        email: whoami.username,
                    });
                }
            }
            setLoaded(true);
        };

        fetchData();

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // waiting for WhoAmI
    if (!isLoaded) {
        return null;
    }

    return (
        <Grid container direction="column" classes={{ root: classes.fullHeight }} wrap="nowrap">
            <Grid item>
                <NavBar theme={theme} />
            </Grid>
            <Grid item xs className="surelync-main-container" classes={{ root: classes.scrollable }}>
                {children}
            </Grid>
        </Grid>
    );
};
