import React from "react";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTable } from "react-table";
import Paper from "@material-ui/core/Paper";
import { ITableColumn, ITableData } from "@surelync/common";
import Typography from "@material-ui/core/Typography";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ViewColumnIcon from "@material-ui/icons/ViewColumn";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import Box from "@material-ui/core/Box/Box";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        scrollable: {
            overflow: "hidden",
            overflowX: "auto",
        },
    })
);

interface ITableProps {
    columns: ITableColumn[];
    data: ITableData[];
}

const Table: React.FC<ITableProps> = ({ columns, data }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const classes = useStyles();
    const { allColumns, getTableProps, headerGroups, rows, prepareRow } = useTable({
        data,
        columns,
    });

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const renderColumnsMenu = (
        <Menu id="hidden-column-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
            {allColumns.map((column) => (
                <MenuItem key={column.id}>
                    {column.Header ? (
                        <label>
                            <input type="checkbox" {...column.getToggleHiddenProps()} /> {column.Header}
                        </label>
                    ) : null}
                </MenuItem>
            ))}
        </Menu>
    );

    return (
        <Grid container spacing={2} data-testid="table-container">
            <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                    <IconButton color="primary" aria-controls="columns-menu" aria-haspopup="true" onClick={handleClick}>
                        <ViewColumnIcon />
                    </IconButton>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Paper classes={{ root: classes.scrollable }}>
                    {renderColumnsMenu}
                    <MaUTable {...getTableProps()} size="small">
                        <TableHead>
                            {headerGroups.map((headerGroup) => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map((column) => (
                                        <TableCell {...column.getHeaderProps()} align="right">
                                            {column.render("Header")}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                prepareRow(row);
                                return (
                                    <TableRow key={index} {...row.getRowProps()}>
                                        {row.cells.map((cell, index) => {
                                            if (cell.column.id === "col0" && cell.value.includes("__group_header")) {
                                                return (
                                                    <TableCell {...cell.getCellProps()} align={"left"}>
                                                        <Box pt={3}>
                                                            <Typography variant="h6">{cell.value.replace("__group_header", "")}</Typography>
                                                        </Box>
                                                    </TableCell>
                                                );
                                            } else {
                                                return (
                                                    <TableCell {...cell.getCellProps()} align={index === 0 ? "left" : "right"}>
                                                        {cell.render("Cell")}
                                                    </TableCell>
                                                );
                                            }
                                        })}
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </MaUTable>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Table;
