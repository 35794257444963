import React, { useContext, useReducer, useState, useMemo, useCallback, useEffect } from "react";
import { Header, PageContainer, SearchForm } from "../components";
import GlobalContext from "../context/global-context";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { i18n, MessageBox, TableContainer } from "@surelync/common";
import { ReportContext } from "../contexts/contexts";
import { getHeaderColumnsI18 } from "./columns.config";
import { initialState, reducer, setError, setHeaderColumns, setLoading, setData, setSearchParams, setTotal } from "../contexts/reducer";
import ReportsApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/ReportsApiClient";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { generateErrorMessage } from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { IReportsRenewals } from "./models";
import { useStyles } from "../styles";
import { Box } from "@material-ui/core";

const MOCKED_DATA = [
    {
        date: "1 Jul 2021",
        renewals_due: "100",
        renewals: "30",
        conversation: "30",
        renewed_annual_premiums: "550",
        renewed_annual_commissions: "550",
        annual_commission: "",
        manual_renewal_ntu: "15",
        manual_renewal_ntu_percent: "15",
        manual_renewal_ntu_premium: "250",
        manual_renewal_ntu_commission: "250",
        auto_renewal_ntu: "",
        auto_renewal_ntu_percent: "",
        auto_renewal_ntu_premium: "20",
        auto_renewal_ntu_commission: "",
    },
    {
        date: "2 Jul 2021",
        renewals_due: "100",
        renewals: "30",
        conversation: "30",
        renewed_annual_premiums: "550",
        renewed_annual_commissions: "550",
        annual_commission: "",
        manual_renewal_ntu: "15",
        manual_renewal_ntu_percent: "15",
        manual_renewal_ntu_premium: "250",
        manual_renewal_ntu_commission: "250",
        auto_renewal_ntu: "",
        auto_renewal_ntu_percent: "",
        auto_renewal_ntu_premium: "20",
        auto_renewal_ntu_commission: "",
    }
];

const MOCKED_FOOTER_DATA = {
    renewals_due_total: "100",
    renewals_total: "30",
    conversation_total: "30",
    renewed_annual_premiums_total: "550",
    renewed_annual_commissions_total: "550",
    annual_commission_total: "",
    manual_renewal_ntu_total: "15",
    manual_renewal_ntu_percent_total: "15",
    manual_renewal_ntu_premium_total: "250",
    manual_renewal_ntu_commission_total: "250",
    auto_renewal_ntu_total: "",
    auto_renewal_ntu_percent_total: "",
    auto_renewal_ntu_premium_total: "20",
    auto_renewal_ntu_commission_total: "",
};

const ReportsRenewals: React.FC = () => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslationNamespaces(i18n, ["surelync"]);
    const theme = useTheme();
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);
    const {
        data,
        error,
        headerColumns,
        isLoading,
        searchParams,
        total
    } = state;

    const reportsApiClient = useMemo(() => new ReportsApiClient(namespacedLocalStorage), [namespacedLocalStorage]);

    useEffect(() => {
        const isMobile = window.innerWidth < theme.breakpoints.values.md;
        const headerColumnsI18 = getHeaderColumnsI18(isMobile, MOCKED_FOOTER_DATA, classes);
        dispatch(setHeaderColumns(headerColumnsI18));
    }, []);

    useEffect(() => {
        if (!searchParams) {
            return;
        }
        let isMount = true;
        const fetchData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await reportsApiClient.getRenewalsReports(searchParams);
                if (!isMount) return;
                dispatch(setData(MOCKED_DATA as IReportsRenewals[]));
                dispatch(setTotal(response.total));
            } catch (error) {
                if (!isMount) return;
                dispatch(setError(generateErrorMessage(error)));
                dispatch(setData(MOCKED_DATA as IReportsRenewals[])); // FIXME - remove fake data
            }
            dispatch(setLoading(false));
        };

        fetchData();

        return () => {
            isMount = false;
        }
    }, [searchParams]);

    const handlePagination = useCallback((limit, offset) => {
        if (searchParams.limit !== limit || searchParams.offset !== offset) {
            dispatch(setSearchParams({...searchParams, limit, offset}));
        }
    }, [searchParams]);

    return (
        <ReportContext.Provider value={[state, dispatch]}>
            <PageContainer title={t("renewals")}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Header title={t("renewals")} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <SearchForm />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {error ? <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch(setError(null))} /> : null}
                        {searchParams ? (
                            <TableContainer
                                theme={theme}
                                columns={headerColumns}
                                data={data}
                                loading={isLoading}
                                needFooter
                                total={total}
                                fetchData={handlePagination}
                            />
                        ) : null}
                    </Grid>
                </Grid>
            </PageContainer>
        </ReportContext.Provider>);
};

export default ReportsRenewals;
