import { IAction, IGlobalState } from "./state.model";

export const SET_CLIENT_LOGO = "SET_CLIENT_LOGO";
export const SET_LOCALE = "SET_LOCALE";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_PRODUCTS = "SET_PRODUCTS";

export const defaultState: IGlobalState = {
    clientLogo: null,
    currentUser: null,
    locale: null,
    products: {},
};

export const globalReducer = (state: IGlobalState, action: IAction) => {
    // console.log(action);
    switch (action.type) {
        case SET_CLIENT_LOGO:
            return { ...state, clientLogo: action.payload };

        case SET_LOCALE:
            return { ...state, locale: action.payload };

        case SET_CURRENT_USER:
            return { ...state, currentUser: action.payload };

        case SET_PRODUCTS:
            return { ...state, products: action.payload };

        default:
            return state;
    }
};
