import BaseApiClient from './BaseApiClient';

export default class ReportsApiClient extends BaseApiClient {
    constructor(...args) {
        super('/report/api/v1/', ...args);
    }

    async getCancellationsReports(params) {
        const config = {params};
        const response = await super.handleGet(`${this.basepath}cancellations`, config);
        super.handleResponse(response);
        return response.data;
    }

    async getNewBusinessReports(params) {
        const config = {params};
        const response = await super.handleGet(`${this.basepath}new-business`, config);
        super.handleResponse(response);
        return response.data;
    }

    async getRenewalsReports(params) {
        const config = {params};
        const response = await super.handleGet(`${this.basepath}renewals`, config);
        super.handleResponse(response);
        return response.data;
    }

    async getPolicyholdersReports(params) {
        const config = {params};
        const response = await super.handleGet(`${this.basepath}policyholders`, config);
        super.handleResponse(response);
        return response.data;
    }
}
