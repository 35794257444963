// Checks for the environment variables and stuffs it into an object
export function valueOrEnvVariable (value, def) {
    return value.indexOf('!!') === 0 ? def : value;
}

export function checkEnv() {
    const env = valueOrEnvVariable('!!env!!', 'dev');
    const location = valueOrEnvVariable('!!location!!', process.env.REACT_APP_LOCATION);
    const version = valueOrEnvVariable('!!version!!', 'master');
    const startTime = valueOrEnvVariable('!!startTime!!', new Date());
    return {env, location, version, startTime: +startTime};
}
