import React from "react";
import { CellInfo } from "react-table";
import { i18n } from "@surelync/common";
import { TableFooter } from "../components";

export const getHeaderColumnsI18 = (isMobile: boolean, footerData: Record<string, string>, classes: Record<string, string>) => [
    {
        Header: () => i18n.t("date"),
        accessor: "date",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("date")],
        },
    },
    {
        Header: () => i18n.t("quotes"),
        accessor: "quotes",
        size: "small",
        toCSV: {
            headers: [i18n.t("quotes")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["quotes_total"]} />
    },
    {
        Header: () => i18n.t("bound"),
        accessor: "bound",
        size: "small",
        toCSV: {
            headers: [i18n.t("bound")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["bound_total"]} />
    },
    {
        Header: () => `% ${i18n.t("bound")}`,
        accessor: "bound_percent",
        size: "small",
        toCSV: {
            headers: [`% ${i18n.t("bound")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["bound_percent_total"]} />
    },
    {
        Header: () => `${i18n.t("bound")} ${i18n.t("annualPremiums")}`,
        accessor: "bound_annual",
        size: "small",
        toCSV: {
            headers: [i18n.t("boundAnnualPremiums")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["bound_annual_total"]} />
    },
    {
        Header: () => i18n.t("annualCommission"),
        accessor: "annual_commission",
        size: "small",
        toCSV: {
            headers: [i18n.t("annualCommission")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["annual_commission_total"]} />
    },
    {
        Header: () => i18n.t("dtq"),
        accessor: "dtq",
        size: "small",
        toCSV: {
            headers: [i18n.t("dtq")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["dtq_total"]} />
    },
    {
        Header: () => `% ${i18n.t("dtq")}`,
        accessor: "dtq_percent",
        size: "small",
        toCSV: {
            headers: [`% ${i18n.t("dtq")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["dtq_percent_total"]} />
    },
    {
        Header: () => i18n.t("referrals"),
        accessor: "referrals",
        size: "small",
        toCSV: {
            headers: [i18n.t("referrals")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["referrals_total"]} />
    },
    {
        Header: () => `% ${i18n.t("referred")}`,
        accessor: "referred",
        size: "small",
        toCSV: {
            headers: [`% ${i18n.t("referred")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["referred_total"]} />
    },
];
