// UNCOMMENT TS AND REPLACE JS WHEN TRANSFER blocksure-core TO TS

// @flow
// import type {ApiResponse} from './BaseApiClient';
import BaseApiClient from './BaseApiClient';

/* Quote API Request/Response types */

// export type QuoteResponse = {
//     +status: 'Dtq' | 'Quoted',
//     +quote: {
//         [string]: {
//             +name: string,
//             +submission: Submission,
//             +quote: {
//                 +premium: string,
//                 +from: string,
//                 +error: ?string,
//                 +meta: {}
//             }
//         }
//     }
// }

// export type Submission = {
//     +sumInsured: string,
//     +entries: { string: any },
// }

export default class QuoteApiClient extends BaseApiClient {
    constructor(...args) {
        super('/quote/api/v1/', ...args);
    }

    // async requestQuote(productId: string, submission: { string: Submission }, productVersion = null): Promise<{ string: QuoteResponse }> {
    //     const data = {productId, productVersion, submission};
    //     const response: ApiResponse<{ string: QuoteResponse }> = await super.handlePost(this.basepath, data);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async requestQuote(productId, submission, productVersion = null) {
        const data = {productId, productVersion, submission};
        const response = await super.handlePost(this.basepath, data);
        super.handleResponse(response);
        return response.data;
    }

    // async requestQuotes(rfqs: { [name]: { productId: string, submission: Submission, productVersion: string } }): Promise<{ string: QuoteResponse }> {
    //     const response: ApiResponse<{ string: QuoteResponse }> = await super.handlePost(`${this.basepath}batch`, rfqs);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async requestQuotes(rfqs) {
        const response = await super.handlePost(`${this.basepath}batch`, rfqs);
        super.handleResponse(response);
        return response.data;
    }
}
