import BaseApiClient from './BaseApiClient';

export default class ImportApiClient extends BaseApiClient {
    constructor(...args) {
        super('/import/api/v1/', ...args);
    }

    async importImages(username, imageFormData, callback) {
        const headers = {'content-type': 'multipart/form-data'};
        const config = {
            onUploadProgress: progressEvent => {
                if (progressEvent.lengthComputable) {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;
                    callback(progress);
                }
            },
            headers
        };
        imageFormData.append('username', JSON.stringify(username));
        const response = await super.handlePost(`${this.basepath}/image`, imageFormData, config);
        super.handleResponse(response);
        return response.data;
    }

    async importData(fileArray) {
        const response = await super.handlePost(`${this.basepath}/import`, fileArray);
        super.handleResponse(response);
        return response.data;
    }
}
