import { IReportsCancellations } from "../@ReportsCancellations/models";
import { IReportsNewBusiness } from "../@ReportsNewBusiness/models";
import { IReportsPolicyholders } from "../@ReportsPolicyholders/models";
import { IReportsRenewals } from "../@ReportsRenewals/models";
import { ISearchParams } from "../models/search.model";

type SearchResponse = Array<IReportsCancellations | IReportsNewBusiness | IReportsPolicyholders | IReportsRenewals>;

enum ActionType {
    SetData,
    SetErrors,
    SetHeaderColumns,
    SetLoading,
    SetSearchParams,
    SetTotal
}

interface IActionSetData {
    type: ActionType.SetData;
    payload: SearchResponse;
}

interface IActionSetErrors {
    type: ActionType.SetErrors;
    payload: string;
}

interface IActionSetHeaderColumns {
    type: ActionType.SetHeaderColumns;
    payload: any;
}

interface IActionSetLoading {
    type: ActionType.SetLoading;
    payload: boolean;
}

interface IActionSetSearchParams {
    type: ActionType.SetSearchParams;
    payload: ISearchParams
}

interface IActionSetTotal {
    type: ActionType.SetTotal;
    payload: number
}

export type Action =
    | IActionSetData
    | IActionSetErrors
    | IActionSetHeaderColumns
    | IActionSetLoading
    | IActionSetSearchParams
    | IActionSetTotal;

export const setData = (data: SearchResponse): IActionSetData => ({ type: ActionType.SetData, payload: data });
export const setError = (error: string | null): IActionSetErrors => ({ type: ActionType.SetErrors, payload: error });
export const setHeaderColumns = (data: any): IActionSetHeaderColumns => ({ type: ActionType.SetHeaderColumns, payload: data });
export const setLoading = (enable: boolean): IActionSetLoading => ({ type: ActionType.SetLoading, payload: enable });
export const setSearchParams = (params: ISearchParams): IActionSetSearchParams => ({ type: ActionType.SetSearchParams, payload: params });
export const setTotal = (value: number): IActionSetTotal => ({ type: ActionType.SetTotal, payload: value });

export type ReportsState = {
    data: SearchResponse | null;
    error: string | null;
    canDownload: boolean;
    headerColumns: any;
    isLoading: boolean;
    searchParams: ISearchParams;
    total: number;
};

export const initialState = {
    data: null,
    error: null,
    canDownload: true,
    headerColumns: null,
    isLoading: false,
    searchParams: null,
    total: 0
};

export function reducer(state: ReportsState = initialState, action: Action): ReportsState {
    switch (action.type) {
        case ActionType.SetData:
            return { ...state, data: action.payload, error: null };
        case ActionType.SetErrors:
            return { ...state, error: action.payload };
        case ActionType.SetHeaderColumns:
            return { ...state, headerColumns: action.payload };
        case ActionType.SetLoading:
            return { ...state, isLoading: action.payload, error: action.payload ? null : state.error };
        case ActionType.SetSearchParams:
            return { ...state, searchParams: action.payload };
        case ActionType.SetTotal:
            return { ...state, total: action.payload };
        default:
            return state;
    }
}
