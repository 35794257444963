import React from 'react';
import {DateTime, Duration, Settings} from 'luxon';
import FontAwesome from 'react-fontawesome';

export const compareDates = (date1, date2, unit) => {
    return date1.diff(date2, unit)
        .toObject()[unit];
};

export const compareISODates = (date1, date2, unit) => {
    return compareDates(parseDate(date1), parseDate(date2), unit);
};

export const formatDateTime = field => params => {
    const value = params.data && params.data[field];
    if (value === null || value === undefined) return '';
    return renderFormatDate(value, 'ff');
};

export const formatDate = field => params => {
    const value = params.data && params.data[field];
    if (value === null || value === undefined) return '';
    return renderFormatDate(value, 'DD');
};

export function formatDateUser(value) {
    if (value === null || value === undefined) return '-';
    return value
        ? renderFormatDate(value, 'HH:mm ${} dd/LL/yyyy')
            .replace('${}', 'on') // fix on -> 365n. 'o' is luxon token - ordinal (day of year)
        : '';
}

export const formatAmount = field => params => {
    const amount = params.data && params.data[field];
    if (amount === null || amount === undefined || Number.isNaN(amount)) return '-';
    return (+amount).toFixed(2);
};

export const formatState = field => params => {
    const state = params.data && params.data[field];
    if (state === null || state === undefined) return '-';
    return +state === 1 ? 'Live' : 'Pending';
};

export const formatValueOrSpinner = field => params => {
    if (params.data)
        return <span>{params.data[field]} </span>;
    return <FontAwesome name="spinner" fixedWidth pulse className="subtle-icon text-center"/>;
};

export function formatPartyName(party) {
    return party && party.split(',')
        .map(it => it.split('='))
        .find(it => it[0] === 'O')[1];
}

export function formatPartyCountry(party) {
    return party && party.split(',')
        .map(it => it.split('='))
        .find(it => it[0] === 'C')[1];
}

// Transactions

export function getTransactionAttempt(transaction, name) {
    if (!transaction) return '-';
    const length = transaction.attempts.length;
    const reference = transaction.attempts[length - 1] && transaction.attempts[length - 1][name];
    if (!reference) return '-';
    return reference;
}

export const minusToday = (value, unit) => {
    return DateTime.local()
        .minus({[unit]: value});
};

export const now = format => {
    const today = DateTime.local();
    return format ? today.toFormat(format) : today;
};

export const parseDate = (value) => {

    if (value instanceof Date) {
        return DateTime.fromJSDate(value);
    }

    if (typeof value === 'number') {
        return DateTime.fromMillis(value);
    }

    if (typeof value === 'string') {
        return DateTime.fromISO(value.replace('[UTC]', '')
            .replace('[GMT]', ''));
    }

    return value;
};

export const parseIsoDuration = frequency => {
    return Duration.fromISO(frequency)
        .toObject();
};

export const plusToday = (value, unit) => {
    return DateTime.local()
        .plus({[unit]: value});
};

export const renderDate = value => {
    if (!value) return null;

    return parseDate(value)
        .toFormat('DD');
};

export const renderDateTime = value => {
    if (!value) return null;

    return parseDate(value)
        .toFormat('ff');
};

export const renderFormatDate = (value, format) => {
    if (!value) return null;

    return parseDate(value)
        .toFormat(format);
};

export const SERVER_DATE_FORMAT = 'yyyy-MM-dd';

export const setGlobalLocale = locale => {
    Settings.defaultLocale = locale;
};

export const sortByProperty = (array, property) => {
    return array.sort((a, b) => {
        return compareISODates(a[property], b[property], 'seconds');
    });
};

export const startOfToday = (unit) => {
    return DateTime.local()
        .startOf(unit);
};

export const today = () => {
    return DateTime.local();
};

// ignore timezone
// datepicker return startOf day with timezone offset
// ue Jan 05 2021 00:00:00 GMT+0200
export const toUTC = (date) => {
    return new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();
};

export const trimEmailValue = (value) => value
    .replace(/ /g, '')
    .replace(/(\r\n|\n|\r)/gm, '');
