import BaseApiClient from './BaseApiClient';


export default class IntegrationApiClient extends BaseApiClient {
    constructor(...args) {
        super('/job/api/v1/integration/', ...args);
        ['list', 'get', 'add', 'update', 'delete', 'run'].forEach(it => {
            this[it] = this[it].bind(this);
        });
    }

    async list() {
        const response = await super.handleGet(this.basepath);
        super.handleResponse(response);
        return response.data;
    }

    async get(id) {
        const response = await super.handleGet(`${this.basepath}${id}`);
        super.handleResponse(response);
        return response.data;
    }

    async run(id) {
        const response = await super.handlePost(`${this.basepath}${id}/run`, {});
        super.handleResponse(response);
        return response.data;
    }

    async add(name, notes, schedule, mode, type, body, parameters) {
        const response = await super.handlePost(this.basepath, {name, notes, schedule, mode, type, body, parameters});
        super.handleResponse(response);
        return response.data;
    }

    async update(id, name, notes, schedule, mode, type, body, parameters) {
        const response = await super.handlePost(`${this.basepath}${id}`, {name, notes, schedule, mode, type, body, parameters});
        super.handleResponse(response);
        return response.data;
    }

    async delete(id) {
        const response = await this.axios.delete(`${this.basepath}${id}`);
        super.handleResponse(response);
        return response.data;
    }

    async listTemplates() {
        const response = await super.handleGet('/job/api/v1/integration-template');
        super.handleResponse(response);
        return response.data;
    }

    async getTemplate(id) {
        const response = await super.handleGet(`/job/api/v1/integration-template/${id}`);
        super.handleResponse(response);
        return response.data;
    }

    async getTemplateParameters(id) {
        const response = await super.handleGet(`/job/api/v1/integration-template/${id}/parameters`);
        super.handleResponse(response);
        return response.data;
    }
}
