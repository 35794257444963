import React from "react";

interface Props {
    classes: Record<string, string>,
    value: string;
}

export const TableFooter: React.FC<Props> = ({classes, value }) => {
    return <span className={classes.totalCol}>{value}</span>;
};
