// @flow
import axios from 'axios';
import i18next from 'i18next';
import qs from 'qs';
import { generateError } from '../../utilities/ErrorHandler';

const NotAuthorized = 'Not Authorized';
const NetworkError = 'Network Error';

export default class BaseApiClient {
    authpath = '';
    basepath = '';
    product = '';
    axios = axios.create({
        paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
    });

    constructor(path, storageOrAppName) {
        this.setPaths(path);
        this.handleRejection = this.handleRejection.bind(this);
        this.axios.interceptors.response.use(r => r, this.handleRejection);
        this.axios.interceptors.request.use((config) => {
            const appName = (storageOrAppName instanceof Object) ? storageOrAppName.getItem('app-name') || 'global' : storageOrAppName;
            config.headers = {...config.headers, 'app-name': appName};
            return config;
        });
    }

    setPaths(path) {
        const [protocol, , domain, , product] = global.window ? window.location.href.split('/', 5) : ['', '', ''];
        this.authpath = `${protocol}//${domain}/auth/api/v1/`;
        this.basepath = `${protocol}//${domain}${path}`;
        this.product = product;
    }

    clearJwtToken() {
        return this.axios.get(`${this.authpath}removeToken`);
    }

    async handleGet(path, config = {}) {
        return this.axios.get(path, config);
    }

    async handlePost(path, data = undefined, config = {}) {
        return this.axios.post(path, data, config);
    }

    handleResponse(response) {
        if (!response) {
            throw new Error('No response received from server');
        } else if (response?.data?.errorKey) {
            throw new Error(generateError(i18next, response?.data, null));
        } else if (response.status === 401) {
            console.error(response.message);
            throw new Error(`${NotAuthorized}: Please sign in again`);
        } else if (Math.floor(response.status / 100) !== 2) {
            console.error(response.message);
            throw new Error(`${NetworkError}: Please contact support`);
        }
    }

    async handleRejection(error) {
        const {config, response} = error;
        if (!response) {
            throw new Error(error);
        } else if (response.status === 401 && response.headers.expiredtoken === 'true') {
            // This is to avoid going to an infinite loop by trying to refresh the token
            if (!config.headers.avoidReauthentication) {
                return this.reauthenticate(config);
            }
        } else if (response.data.errorKey) {
            return response;
        }

        throw error;
    }

    async reauthenticate(config) {
        const {data} = await this.axios.get(`${this.authpath}refresh`, {headers: {avoidReauthentication: true}});
        const headers = {...config.headers, Authorization: `Bearer ${data}`};
        // Axios seems to  ignore the header 'application/json' if it's already serialised the data object in the first try, so in that case we re-parse it.
        const isJson = !!config.headers['Content-Type'] && config.headers['Content-Type'].startsWith('application/json');
        const newData = isJson ? JSON.parse(config.data) : config.data;
        const newConfig = Object.assign(config, {data: newData, headers: {...headers, clientretry: true}});
        if (this.onReauth) this.onReauth(data);
        return this.axios.request(newConfig);
    }
}
