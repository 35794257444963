
// i18n can have multiple namespaces ["surebyld", "surelync"];
// try to find the translation value in each JSON file.

export function useTranslationNamespaces(i18n, namespaces) {
    return {
        t (key, params) {
            let result;
            namespaces.forEach((ns) => {
                if (result) {
                    return;
                }
                const value = i18n.t(key, { ...params, ns });
                if (value !== key) {
                    result = value;
                }
            });
            return result || key;
        }
    };
}
