import React from 'react';
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { BannerClientLogo } from "../BannerClientLogo/BannerClientLogo";

interface Props {
    title: string;
}

export const Header: React.FC<Props> = ({ title }) => {
    return (
        <Grid container alignItems="flex-end">
            <Grid item xs={12} sm={8} md={5}>
                <Typography variant="h5">{title}</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
                <Box textAlign={{ xs: "right", md: "center" }}>
                    <BannerClientLogo />
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={5} />
        </Grid>
    )
};
