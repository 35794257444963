import BaseApiClient from './BaseApiClient';

export default class InfraApiClient extends BaseApiClient {
    constructor(...args) {
        super('/infra/api/v1/', ...args);
    }

    async listConfig() {
        const response = await super.handleGet(`${this.basepath}config`);
        super.handleResponse(response);
        return response.data;
    }

    async getConfigEntry(key) {
        const response = await super.handleGet(`${this.basepath}config/${key}`);
        super.handleResponse(response);
        return response.data;
    }

    async createConfigEntry(key, value, type) {
        const response = await super.handlePost(`${this.basepath}config`, {key, value, type});
        super.handleResponse(response);
        return response.data;
    }

    async updateConfigEntry(key, value, type) {
        const response = await super.handlePost(`${this.basepath}config/${key}`, {value, type});
        super.handleResponse(response);
        return response.data;
    }

    async deleteConfigEntry(key) {
        const response = await this.axios.delete(`${this.basepath}config/${key}`);
        super.handleResponse(response);
        return response.data;
    }

    async listNetwork() {
        const response = await super.handleGet(`${this.basepath}corda`);
        super.handleResponse(response);
        return response.data;
    }

    async featureToggles() {
        const response = await super.handleGet(`${this.basepath}feature`);
        super.handleResponse(response);
        return response.data;
    }

    async getPods() {
        const response = await super.handleGet(`${this.basepath}k8s/pods`);
        super.handleResponse(response);
        return response.data;
    }

    async getLogs(pod, container) {
        const response = await super.handleGet(`${this.basepath}k8s/pods/${pod}/${container}/log`);
        super.handleResponse(response);
        return response.data;
    }
}
