import React from "react";
import { IGlobalState } from "./state.model";
import { namespacedLocalStorage, signInService } from "@surelync/common";

const GlobalContext = React.createContext<IGlobalState>({
    clientLogo: null,
    currentUser: {
        authDetails: signInService.loadAuthDetails(),
        userDetails: signInService.loadUserDetails(),
    },
    locale: null,
    namespacedLocalStorage,
    products: null
});

export default GlobalContext;
