import BaseApiClient from './BaseApiClient';

export default class FailedPaymentApiClient extends BaseApiClient {
    constructor(...args) {
        super('/payment-gateway/api/v1/mock/', ...args);
        ['list', 'get', 'create', 'update', 'delete'].forEach(it => {
            this[it] = this[it].bind(this);
        });
    }

    async list() {
        const response = await super.handleGet(this.basepath);
        super.handleResponse(response);
        return response.data;
    }

    async get(id) {
        const response = await super.handleGet(`${this.basepath}${id}`);
        super.handleResponse(response);
        return response.data;
    }

    async create(transactionId, action, token, count) {
        const response = await super.handlePost(this.basepath, {transactionId, action, count});
        super.handleResponse(response);
        return response.data;
    }

    async update(id, action, token, count) {
        const response = await super.handlePost(`${this.basepath}${id}`, {action, count});
        super.handleResponse(response);
        return response.data;
    }

    async delete(id) {
        const response = await this.axios.delete(`${this.basepath}${id}`);
        super.handleResponse(response);
        return response.data;
    }
}
