/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import defaultJsonLang from '../translations/exception-translation-en.json';

const generateTranslation = (i18next) => {
    const key = i18next.language || localStorage.getItem('i18nextLng');
    const currentJsonLang = require(`../translations/exception-translation-${key}.json`);
    return currentJsonLang;
};

export const generateError = (i18next, response, defaultTranslation) => {
    if (!response?.errorKey) return defaultTranslation;
    const currentJsonLang = generateTranslation(i18next) || defaultJsonLang;
    let errorMessage = currentJsonLang[response.errorKey] || response.description;

    try {
        const params = Object.keys(response.parameters);
        params.forEach((param) => {
            const responseParam = response.parameters[param];
            errorMessage = errorMessage.replace(param, responseParam);
        });
    } catch (error) {
        console.log(error);
    }

    return errorMessage;
};

export const generateErrorMessage = (error, message) => error.message || error.toString() || message;
