import React, { useContext, useEffect } from "react";
import Fade from "@material-ui/core/Fade";
import Box from "@material-ui/core/Box";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import clsx from "clsx";
import GlobalContext from "../../context/global-context";
import { Preloader } from "@surelync/common";
import { Container, useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            height: "100%",
            [theme.breakpoints.down("md")]: {
                padding: 0,
            },
        },
        wrapper: {
            paddingLeft: theme?.spacing(2),
            paddingRight: theme?.spacing(2),
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme?.spacing(3),
                paddingRight: theme?.spacing(3),
            },
            paddingTop: 5,
            paddingBottom: 25,
        },
    })
);

interface IProps {
    isBreadcrumb?: boolean;
    fetching?: boolean;
    title: string;
    noPadding?: boolean; // At the home page we have a drawer, must be full height
}

export const PageContainer: React.FC<IProps> = ({ children, fetching, isBreadcrumb, noPadding, title }) => {
    const classes = useStyles();
    const { locale } = useContext(GlobalContext);
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();

    useEffect(() => {
        document.title = `${title ? title + " - " : ""}SureLync`;
        let isMount = true;
        const timerId = setTimeout(() => {
            isMount && setOpen(true);
        }, 0);

        return () => {
            isMount = false;
            clearTimeout(timerId);
        };
    }, [locale, title]);

    if (fetching) {
        return <Preloader fullHeight={true} theme={theme} />;
    }

    return (
        <Fade in={open}>
            <Box
                className={clsx([
                    classes.root,
                    noPadding ? "" : classes.wrapper, // Search page include Sidebar
                ])}
            >
                <Container maxWidth="xl" className={classes.root}>
                    {children}
                </Container>
            </Box>
        </Fade>
    );
};
