import BaseApiClient from './BaseApiClient';

export default class PaymentApiClient extends BaseApiClient {
    constructor(...args) {
        super('/payment/api/v1/', ...args);
    }

    async registerPaymentSource(paymentGateway, policyholderId, paymentSource) {
        const response = await super.handlePost(`${this.basepath}${paymentGateway}/source/`, {policyholderId, paymentSource});
        super.handleResponse(response);
        return response.data;
    }

    async getStripeAccountDetails(paymentGateway) {
        try {
            const response = await super.handleGet(`${this.basepath}${paymentGateway}/stripe/account/`);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async getStripeAccountBalance(paymentGateway) {
        try {
            const response = await super.handleGet(`${this.basepath}${paymentGateway}/stripe/account/balance`);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async createUpdateStripeAccount(paymentGateway, request, verificationDocument) {
        try {
            const formData = new FormData();
            formData.append('customer-details', JSON.stringify(request));
            if (verificationDocument) {
                formData.append('verification-document', verificationDocument, verificationDocument.name);
            }
            const headers = {'content-type': 'multipart/form-data'};
            const response = await super.handlePost(`${this.basepath}${paymentGateway}/stripe/account/`, formData, {headers});
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async search(query, limit = 10000, offset = 0) {
        try {
            const config = {params: {limit, offset, ...query}};
            const response = await super.handleGet(`${this.basepath}`, config);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    // TODO - Settlements from different payment gateways can't be settled at the same time. PLAT-2014
    async settle(settlementIds) {
        const response = await super.handlePost(`${this.basepath}settlement`, settlementIds);
        super.handleResponse(response);
        return response.data;
    }

    async getPaymentSources(paymentGateway, policyholderId) {
        try {
            const response = await super.handleGet(`${this.basepath}${paymentGateway}/source/${policyholderId}`);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    // Updated - now points to an updated /batch endpoint
    async listBatches(limit = 500, offset = 0) {
        try {
            const response = await super.handleGet(`${this.basepath}batch`, {params: {limit, offset}});
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    // Updated - now points to an updated /batch/{id} endpoint
    async getSettlementBatch(id) {
        try {
            const response = await super.handleGet(`${this.basepath}batch/${id}`);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async getSettlement(id) {
        try {
            const response = await super.handleGet(`${this.basepath}${id}`);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async getCurrencies() {
        try {
            const response = await super.handleGet(`${this.basepath}currency`);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }
}
