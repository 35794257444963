import BaseApiClient from './BaseApiClient';

export default class AtfApiClient extends BaseApiClient {
    constructor(...args) {
        super('/atf/api/v1/', ...args);
    }

    async runTests(group) {
        const response = await super.handlePost(`${this.basepath}${group}`);
        super.handleResponse(response);
        return response.data;
    }

    async getTests(group) {
        const response = await super.handleGet(`${this.basepath}${group}`);
        super.handleResponse(response);
        return response.data;
    }
}
