import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageContainer } from "../components";
import Typography from "@material-ui/core/Typography";
import PolicyApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/PolicyApiClient";
import {generateErrorMessage} from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import GlobalContext from "../context/global-context";
import { aggregateStats, averageColumns, flatData } from "./helper";
import Table from "./components/Table";
import { ITableData } from "./models";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { createStyles, makeStyles, Theme, useTheme } from "@material-ui/core/styles";
import { Preloader, MessageBox } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import Box from "@material-ui/core/Box";
import { BannerClientLogo } from "../components/BannerClientLogo/BannerClientLogo";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrapper: {
            margin: 0,
            padding: 0,
        },
    })
);

const ReportsPage: React.FC = () => {
    const [data, setData] = useState<ITableData[]>(null);
    const [headerColumns, setHeaderColumns] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const classes = useStyles();
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslation();
    const theme = useTheme();

    const policyApiClient = new PolicyApiClient(namespacedLocalStorage);

    useEffect(() => {
        const fetchData = async () => {
            setError(null);
            setIsLoading(true);
            try {
                const result = (await aggregateStats(policyApiClient)).map(averageColumns);

                formatTableData(result);
            } catch (error) {
                setError(generateErrorMessage(error));
            }
            setIsLoading(false);
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formatTableData = (data: Response) => {
        const headerColumns = ["ytd", "lastQuarter", "currentQuarter", "monthToDate", "today", "total"].map((column) => t(column));

        headerColumns.unshift("");
        const headerColumnsI18 = headerColumns.map((column, idx) => ({
            Header: column,
            accessor: `col${idx}`,
        }));

        setHeaderColumns(headerColumnsI18);

        const columnsPol = [
            { name: t("number"), path: "policies.number" },
            { name: t("gwp"), path: "policies.gwp", format: true },
            { name: t("averagePremium"), path: "policies.premium", format: true },
            { name: t("commission"), path: "policies.commission", format: true },
            { name: t("averageCommission"), path: "policies.commissionAv", format: true },
            { name: t("conversion"), path: "conversion" },
        ];

        const columnsTotal = [
            { name: t("number"), path: "quotes.number" },
            { name: t("totalPremium"), path: "quotes.gwp", format: true },
            { name: t("averagePremium"), path: "quotes.premium", format: true },
            { name: t("ntus"), path: "quotes.ntus.number" },
            { name: t("ntuGWP"), path: "quotes.ntus.gwp", format: true },
            { name: t("quoteNtuPercentage"), path: "quotes.ntus.pc" },
        ];

        const columnsDecline = [
            { name: t("number"), path: "dtqs.number" },
            { name: t("declinePercentage"), path: "dtqs.pc" },
        ];

        const dataPolicies = flatData(data, columnsPol);
        const dataTotal = flatData(data, columnsTotal);
        const dataDecline = flatData(data, columnsDecline);
        const groupHeader = (name: string) => `${name}__group_header`;

        dataPolicies.unshift({ col0: groupHeader(t("policiesSold")) });
        dataTotal.unshift({ col0: groupHeader(t("totalQuote")) });
        dataDecline.unshift({ col0: groupHeader(t("declines")) });

        setData([...dataPolicies, ...dataTotal, ...dataDecline]);
    };

    return (
        <PageContainer title={t("overview")}>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Grid container alignItems="flex-end">
                        <Grid item xs={12} sm={8} md={5}>
                            <Typography variant="h5">{t("overview")}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={4} md={2}>
                            <Box textAlign={{ xs: "right", md: "center" }}>
                                <BannerClientLogo />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={5} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2">{t("overviewOfPolicies", { "current date": FormatUtils.now("fff") })}</Typography>
                </Grid>

                <Grid item xs={12}>
                    {isLoading && <Preloader fullHeight={true} theme={theme} />}
                    {error ? <MessageBox message={error} theme={theme} variant="error" onClose={() => setError(null)} /> : null}

                    {data ? (
                        <Container maxWidth="md" classes={{ root: classes.wrapper }}>
                            <Table columns={headerColumns} data={data} />
                        </Container>
                    ) : null}
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default ReportsPage;
