import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import { I18nextProvider } from "react-i18next";

import CssBaseline from "@material-ui/core/CssBaseline";
import { createGenerateClassName, StylesProvider, ThemeProvider } from "@material-ui/core/styles";

import { EIdentityType, history, i18n, PageNotFoundPage, theme } from "@surelync/common";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import { Layout } from "./components/Layout/Layout";
import GlobalState from "./context/GlobalState";
import ReportsPage from "./@Reports/ReportsPage";
import ReportsCancellations from "./@ReportsCancellations/ReportsCancellations";
import ReportsNewBusiness from "./@ReportsNewBusiness/ReportsNewBusiness";
import ReportsPolicyholders from "./@ReportsPolicyholders/ReportsPolicyholders";
import ReportsRenewals from "./@ReportsRenewals/ReportsRenewals";

const BROKER_AND_INSURER_AND_MGA = [EIdentityType.Broker, EIdentityType.Insurer, EIdentityType.MGA];

function App() {
    return (
        <GlobalState>
            <I18nextProvider i18n={i18n}>
                {/* Theme not applied to the component from @surelync/common */}
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Layout>
                        <Router history={history}>
                            <Switch>
                                <PrivateRoute
                                    path="/reports/cancellations"
                                    identityTypes={BROKER_AND_INSURER_AND_MGA}
                                    component={ReportsCancellations}
                                />
                                <PrivateRoute
                                    path="/reports/new-business"
                                    identityTypes={BROKER_AND_INSURER_AND_MGA}
                                    component={ReportsNewBusiness}
                                />
                                <PrivateRoute
                                    path="/reports/policyholders"
                                    identityTypes={BROKER_AND_INSURER_AND_MGA}
                                    component={ReportsPolicyholders}
                                />
                                <PrivateRoute
                                    path="/reports/renewals"
                                    identityTypes={BROKER_AND_INSURER_AND_MGA}
                                    component={ReportsRenewals}
                                />
                                {/* <Switch> will render the first <PrivateRoute> that matches that pattern.
                                 * That means that order is important: put generic /reports second last, before the "* is 404" mapping.
                                 */}
                                <PrivateRoute
                                    path="/reports"
                                    identityTypes={BROKER_AND_INSURER_AND_MGA}
                                    component={ReportsPage}
                                />
                                <Route path="*" component={() => <PageNotFoundPage theme={theme} />} />
                            </Switch>
                        </Router>
                    </Layout>
                </ThemeProvider>
            </I18nextProvider>
        </GlobalState>
    );
}

const generateClassName = createGenerateClassName({
    productionPrefix: "surelync",
    seed: "reports",
});

export default function Root(): JSX.Element {
    // Fix: Application unexpected behavior on clicking browser history back/forward buttons
    if (`/surelync${history.location.pathname}` !== window.location.pathname) {
        history.replace(window.location.pathname.replace(`/surelync`, ""));
    }

    return (
        <StylesProvider generateClassName={generateClassName}>
            <App />
        </StylesProvider>
    );
}
