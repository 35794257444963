import React, { useContext, useEffect, useReducer, useState, useMemo, useCallback } from "react";
import { Header, PageContainer, SearchForm } from "../components";
import GlobalContext from "../context/global-context";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { i18n, MessageBox, TableContainer } from "@surelync/common";
import { ReportContext } from "../contexts/contexts";
import { getHeaderColumnsI18 } from "./columns.config";
import { initialState, reducer, setError, setHeaderColumns, setLoading, setData, setSearchParams, setTotal } from "../contexts/reducer";
import ReportsApiClient from "@blocksure/blocksure-core/dist/src/services/api-clients/ReportsApiClient";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { generateErrorMessage } from '@blocksure/blocksure-core/dist/src/utilities/ErrorHandler';
import { IReportsNewBusiness } from "./models";
import { useStyles } from "../styles";
import { Box } from "@material-ui/core";

const MOCKED_DATA = [
    {
        date: "1 Jul 2021",
        quotes: "15",
        bound: "10",
        bound_percent: "67",
        bound_annual: "5",
        annual_commission: "",
        dtq: "3",
        dtq_percent: "20",
        referrals: "2",
        referred: "13",
    },
    {
        date: "2 Jul 2021",
        quotes: "15",
        bound: "10",
        bound_percent: "67",
        bound_annual: "5",
        annual_commission: "",
        dtq: "3",
        dtq_percent: "20",
        referrals: "2",
        referred: "13",
    }
];

const MOCKED_FOOTER_DATA = {
    quotes_total: "3",
    bound_total: "4",
    bound_percent_total: "5",
    bound_annual_total: "6",
    annual_commission_total: "7",
    dtq_total: "8",
    dtq_percent_total: "9",
    referrals_total: "10",
    referred_total: "11",
};
const ReportsNewBusiness: React.FC = () => {
    const { namespacedLocalStorage } = useContext(GlobalContext);
    const { t } = useTranslationNamespaces(i18n, ["surelync"]);
    const theme = useTheme();
    const classes = useStyles();
    const [state, dispatch] = useReducer(reducer, initialState);

    const {
        data,
        error,
        headerColumns,
        isLoading,
        searchParams,
        total
    } = state;

    const reportsApiClient = useMemo(() => new ReportsApiClient(namespacedLocalStorage), [namespacedLocalStorage]);

    useEffect(() => {
        const isMobile = window.innerWidth < theme.breakpoints.values.md;
        const headerColumnsI18 = getHeaderColumnsI18(isMobile, MOCKED_FOOTER_DATA, classes);
        dispatch(setHeaderColumns(headerColumnsI18));
    }, []);

    useEffect(() => {
        if (!searchParams) {
            return;
        }
        let isMount = true;
        const fetchData = async () => {
            dispatch(setLoading(true));
            try {
                const response = await reportsApiClient.getNewBusinessReports(searchParams);
                if (!isMount) return;
                dispatch(setData(MOCKED_DATA as IReportsNewBusiness[]));
                dispatch(setTotal(response.total));
            } catch (error) {
                if (!isMount) return;
                dispatch(setError(generateErrorMessage(error)));
                dispatch(setData(MOCKED_DATA as IReportsNewBusiness[])); // FIXME - remove fake data
            }
            dispatch(setLoading(false));
        };

        fetchData();

        return () => {
            isMount = false;
        }
    }, [searchParams]);

    const handlePagination = useCallback((limit, offset) => {
        if (searchParams.limit !== limit || searchParams.offset !== offset) {
            dispatch(setSearchParams({...searchParams, limit, offset}));
        }
    }, [searchParams]);

    return (
        <ReportContext.Provider value={[state, dispatch]}>
            <PageContainer title={t("newBusinessConversions")}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Header title={t("newBusinessConversions")} />
                    </Grid>
                    <Grid item xs={12}>
                        <Box my={2}>
                            <SearchForm />
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        {error ? <MessageBox message={error} theme={theme} variant="error" onClose={() => dispatch(setError(null))} /> : null}
                        {searchParams ? (
                            <TableContainer
                                theme={theme}
                                columns={headerColumns}
                                data={data}
                                loading={isLoading}
                                needFooter
                                total={total}
                                fetchData={handlePagination}
                            />
                        ) : null}
                    </Grid>
                </Grid>
            </PageContainer>
        </ReportContext.Provider>
    );
};

export default ReportsNewBusiness;
