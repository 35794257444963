import React from "react";
import { CellInfo } from "react-table";
import { i18n } from "@surelync/common";
import { TableFooter } from "../components";

const renderCollWithBorder = (row, classes) => {
    return <span className={classes.borderedCol}>{row.value}</span>;
};

export const getHeaderColumnsI18 = (isMobile: boolean, footerData: Record<string, string>, classes: Record<string, string>) => [
    {
        Header: () => i18n.t("date"),
        accessor: "date",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("date")],
        },
    },
    {
        Header: () => `${i18n.t("renewals")} ${i18n.t("due")}`,
        accessor: "renewals_due",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("renewals")} ${i18n.t("due")}`],
        },
        Cell: (row: CellInfo) => renderCollWithBorder(row, classes),
        Footer: () => <TableFooter classes={classes} value={footerData["renewals_due_total"]} />
    },
    {
        Header: () => `% ${i18n.t("renewals")}`,
        accessor: "renewals",
        size: "small",
        toCSV: {
            headers: [`% ${i18n.t("renewals")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["renewals_total"]} />
    },
    {
        Header: () => `% ${i18n.t("conversion")}`,
        accessor: "conversion",
        size: "small",
        toCSV: {
            headers: [`% ${i18n.t("conversion")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["conversion_total"]} />
    },
    {
        Header: () => `${i18n.t("renewed")} ${i18n.t("annualPremiums")}`,
        accessor: "renewed_annual_premiums",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("renewed")} ${i18n.t("annualPremiums")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["renewed_annual_premiums_total"]} />
    },
    {
        Header: () => `${i18n.t("renewed")} ${i18n.t("annualCommission")}`,
        accessor: "annual_commission",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("renewed")} ${i18n.t("annualCommission")}`],
        },
        Cell: (row: CellInfo) => renderCollWithBorder(row, classes),
        Footer: () => <TableFooter classes={classes} value={footerData["annual_commission_total"]} />
    },
    {
        Header: () => `${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")}`,
        accessor: "manual_renewal_ntu",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["manual_renewal_ntu_total"]} />
    },
    {
        Header: () => `${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")} %`,
        accessor: "manual_renewal_ntu_percent",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")} %`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["manual_renewal_ntu_percent_total"]} />
    },
    {
        Header: () => `${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("premium")}`,
        accessor: "manual_renewal_ntu_premium",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("premium")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["manual_renewal_ntu_premium_total"]} />
    },
    {
        Header: () => `${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("commission")}`,
        accessor: "manual_renewal_ntu_commission",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("manual")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("commission")}`],
        },
        Cell: (row: CellInfo) => renderCollWithBorder(row, classes),
        Footer: () => <TableFooter classes={classes} value={footerData["manual_renewal_ntu_commission_total"]} />
    },
    {
        Header: () => `${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")}`,
        accessor: "auto_renewal_ntu",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["auto_renewal_ntu_total"]} />
    },
    {
        Header: () => `${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")} %`,
        accessor: "auto_renewal_ntu_percent",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")} %`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["auto_renewal_ntu_percent_total"]} />
    },
    {
        Header: () => `${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("commission")}`,
        accessor: "auto_renewal_ntu_premium",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("commission")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["auto_renewal_ntu_premium_total"]} />
    },
    {
        Header: () => `${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("premium")}`,
        accessor: "auto_renewal_ntu_commission",
        size: "small",
        toCSV: {
            headers: [`${i18n.t("auto")} ${i18n.t("renewal")} ${i18n.t("ntu")} ${i18n.t("premium")}`],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["auto_renewal_ntu_commission_total"]} />
    },
];
