import BaseApiClient from './BaseApiClient';

export default class BlobApiClient extends BaseApiClient {
    constructor(...args) {
        super('/blob/api/v1/', ...args);
    }

    async setBlob(data, options) {
        const response = await super.handlePost(`${this.basepath}`, data, options);
        super.handleResponse(response);
        return response.data;
    }

    async assignTag(hash, tag) {
        const response = await super.handlePost(`${this.basepath}${hash}/${tag}`);
        super.handleResponse(response);
        return response.data;
    }

    async assignTagGlobal(hash, tag, readScopes, writeScopes) {
        const params = {'read-scopes': readScopes || '*', 'write-scopes': writeScopes || '*'};
        const response = await super.handlePost(`${this.basepath}${hash}/global/${tag}`, {}, {params});
        super.handleResponse(response);
        return response.data;
    }

    async retrievedTag(tag) {
        const response = await super.handleGet(`${this.basepath}tag/${tag}`);
        super.handleResponse(response);
        return response.data;
    }

    async retrievedTagGlobal(tag) {
        const response = await super.handleGet(`${this.basepath}tag/global/${tag}`);
        super.handleResponse(response);
        return response.data;
    }

    async retrievedHash(hash, options) {
        const response = await super.handleGet(`${this.basepath}${hash}`, options);
        super.handleResponse(response);
        return response.data;
    }

}
