import { ITableData } from "./models";
import get from "lodash/get";
import { EPolicyStatus, CurrencyUtils, PlaceHolder } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";

export const structColumns = () => ({
    policies: {
        number: null,
        gwp: null,
        commission: null,
        premium: null,
        conversion: null,
        commissionAv: null,
    },
    quotes: {
        number: null,
        premium: null,
        gwp: null,
        ntus: { pc: null, number: null, gwp: null },
    },
    dtqs: {
        number: null,
        pc: null,
    },
    cancelled: { number: null, pc: null },
    total: null,
});

export const average = (num, denom) => denom && (num / denom).toFixed(2);

export const averageColumns = (column) => {
    column.policies.premium = average(column.policies.gwp, column.policies.number);
    column.quotes.premium = average(column.quotes.gwp, column.quotes.number);
    column.policies.commissionAv = average(column.policies.commission, column.policies.number);
    // column.dtqs.pc = column.total && `${((column.dtqs.number / column.total) * 100).toFixed(2)}%`;
    column.dtqs.pc = column.dtqs.number && `${((column.dtqs.number / (column.quotes.number + column.dtqs.number)) * 100).toFixed(2)}%`;
    column.quotes.ntus.pc = column.total && column.quotes.number && `${((column.quotes.ntus.number / column.quotes.number) * 100).toFixed(2)}%`;
    column.conversion = column.quotes.number && `${((column.policies.number / (column.quotes.number + column.dtqs.number)) * 100).toFixed(2)}%`;
    return column;
};

export function mergeRows(acc, { grossPremium, totals, status }) {
    acc.total += 1;

    if (status === "DTQ") {
        // Add averages
        acc.dtqs.number += 1;
        return acc;
    }

    const premiumAmount = grossPremium.delta;
    const commission = (totals.Brokerage && totals.Brokerage.delta) || 0;
    const { policies, quotes, cancelled } = acc;

    if (status === EPolicyStatus.Bound) {
        policies.number += 1;
        policies.gwp += premiumAmount;
        policies.commission += commission;
        quotes.number += 1;
        quotes.gwp += premiumAmount;
        // Add Averages
    } else if (status === EPolicyStatus.Quoted) {
        quotes.number += 1;
        quotes.gwp += premiumAmount;
        quotes.ntus.number += 1;
        quotes.ntus.gwp += premiumAmount;
        // Add averages
    } else if (status === EPolicyStatus.Cancelled) {
        cancelled.number += 1;
        // Add averages
    }
    return acc;
}

export const aggregateStats = async (
    policyApiClient,
    offset = 0,
    acc = Array(6)
        .fill(null)
        .map(() => structColumns())
) => {
    const load = ["load.breakdown"];
    // Really crude; need to paginate these properly
    const policies = await policyApiClient.search({}, '', offset, load).then(({ items }) => items);
    // Do not filter above this point.
    const lookForMore = policies.length === 10000;

    acc = policies
        .filter((it) => it.sequence === 0)
        .reduce(([year, lastQuarter, currentQuarter, month, day, rest], policy) => {
            const d = +FormatUtils.parseDate(policy.issueDate || policy.quoteDate); // the issueDate  is only populated when a Quote or Policy is issued
            const lastQuarterM = FormatUtils.minusToday(1, "quarters");
            // Today's bucket
            if (d > +FormatUtils.startOfToday("day")) day = mergeRows(day, policy);
            // This quarter's bucket
            if (d > +FormatUtils.startOfToday("quarter")) currentQuarter = mergeRows(currentQuarter, policy);
            // Last Quarter's bucket
            if (d > +lastQuarterM.startOf("quarter") && d < +lastQuarterM.endOf("quarter")) lastQuarter = mergeRows(lastQuarter, policy);
            // This Month's Bucket
            if (d > +FormatUtils.startOfToday("month")) month = mergeRows(month, policy);
            // This Year's Bucket
            if (d > +FormatUtils.startOfToday("year")) year = mergeRows(year, policy);
            // Total Bucket
            rest = mergeRows(rest, policy);
            return [year, lastQuarter, currentQuarter, month, day, rest];
        }, acc);

    return lookForMore ? aggregateStats(policyApiClient, offset + 1, acc) : acc;
};

export const formatValue = (val: number, format?: boolean): string => {
    if (format && val) {
        return CurrencyUtils._renderCurrency(val, "en-GB");
    } else if (val === null) {
        return PlaceHolder;
    }
    return val.toString();
};

export const flatData = (data, columns: { name: string; path: string; format?: boolean }[]): ITableData[] => {
    const result = [];

    columns.forEach((rowData) => {
        const row = {};

        data.forEach((agg, index) => {
            const colName = `col${index + 1}`;
            const value = get(agg, rowData.path);

            if (index === 0) {
                row["col0"] = rowData.name;
            }

            row[colName] = formatValue(value, rowData.format);
        });
        result.push(row);
    });

    return result;
};
