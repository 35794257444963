import React, { useContext, useRef, useMemo, useState } from "react";
import GlobalContext from "../../context/global-context";
import Grid from "@material-ui/core/Grid";
import { useTheme } from "@material-ui/core/styles";
import { AutocompleteCustom, DatePickerCustom, EIdentityType, i18n, generateCsvData, SelectCustom, ProductUtils, IProduct } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { useFormik } from "formik";
import { useTranslationNamespaces } from '@blocksure/blocksure-core/dist/src/utilities';
import { Frequency } from "../../models/search.model";
import { Box, Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { ReportContext } from "../../contexts/contexts";
import { setData, setSearchParams } from "../../contexts/reducer";
import { CSVLink } from "react-csv";

const isInsurer = (identityType: EIdentityType) => identityType === EIdentityType.Insurer;

export const SearchForm: React.FC = () => {
    const { currentUser, locale, products } = useContext(GlobalContext);
    const [{data, headerColumns, searchParams}, dispatch] = useContext(ReportContext);
    const [csvData, setCsvData] = useState([]);
    const { t } = useTranslationNamespaces(i18n, ["surelync"]);
    const theme = useTheme();
    const csvLinkEl = useRef(null);
    const defaultOption = { label: t("all"), value: "all" };

    const filename = useMemo(() => `${new Date().getTime()}_${t("**FIXME**")}_SureLync.csv`, [t]);

    const formik = useFormik({
        initialValues: {
            dateFrom: null,
            dateTo: null,
            frequency: "daily" as Frequency,
            insurer: "all",
            productId: "all",
        },
        onSubmit: async (values) => {
            const { dateFrom, dateTo, ...others } = values;
            const { limit = 10, offset = 0 } = searchParams || {};
            const params = {
                ...others,
                from: FormatUtils.renderFormatDate(dateFrom, FormatUtils.SERVER_DATE_FORMAT),
                to: FormatUtils.renderFormatDate(dateTo, FormatUtils.SERVER_DATE_FORMAT),
                limit,
                offset
            };
            dispatch(setSearchParams(params));
        }
    });

    const handleDownloadCSV = () => {
        const { sortIndexes } = (data || {}) as any;
        const sortedData = sortIndexes ? sortIndexes.map((i) => data[i]) : [];
        setCsvData(generateCsvData(headerColumns, sortedData));
        setTimeout(() => csvLinkEl.current?.link.click(), 100);
    };

    const optionsProduct = useMemo(() => {
        const { insurer } = formik.values;
        let data;
        if (insurer === "all") {
            data = Object.values(products);
        } else {
            data = Object.values(products).filter(product => Object.values(product.partiesAndRoles).includes(insurer));
        }

        return [
            defaultOption,
            ...data
                .map((product) => ({ label: product.name, value: product.id }))
                .sort((option1, option2) => option1.label.localeCompare(option2.label))
        ]
    }, [products, formik.values.insurer]);

    const optionsInsurer = useMemo(() => {
        const result = {};
        const { productId } = formik.values;
        const getParties = (product: IProduct) => Object.values(product.partiesAndRoles).forEach(party => result[party] = true);

        if (productId === "all") {
            Object.values(products).forEach((product) => getParties(product));
        } else {
            getParties(products[productId]);
        }

        return [
            defaultOption,
            ...Object.keys(result).sort().map(party => ({ label: ProductUtils._formatParty(party), value: party }))
        ];
    }, [products, formik.values.productId]);

    return (
        <>
            <Grid container alignItems="center" justify="space-between">
                <Grid item xs={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={4}>
                            <DatePickerCustom
                                displayValue={formik.values.dateFrom ? FormatUtils.renderFormatDate(formik.values.dateFrom, "D") : ""}
                                label={t("startDate")}
                                locale={locale}
                                maxDate={formik.values.dateTo}
                                required
                                theme={theme}
                                value={formik.values.dateFrom ? FormatUtils.parseDate(formik.values.dateFrom).toJSDate() : null}
                                onChange={(value) => {
                                    dispatch(setData(null))
                                    formik.setFieldValue("dateFrom", value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <DatePickerCustom
                                displayValue={formik.values.dateTo ? FormatUtils.renderFormatDate(formik.values.dateTo, "D") : ""}
                                label={t("endDate")}
                                locale={locale}
                                minDate={formik.values.dateFrom}
                                required
                                theme={theme}
                                value={formik.values.dateTo ? FormatUtils.parseDate(formik.values.dateTo).toJSDate() : null}
                                onChange={(value) => {
                                    dispatch(setData(null))
                                    formik.setFieldValue("dateTo", value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <SelectCustom
                                label={t("timePeriod")}
                                name="frequency"
                                options={[
                                    { label: t("daily"), value: "daily" },
                                    { label: t("weekly"), value: "weekly" },
                                    { label: t("monthly"), value: "monthly" },
                                    { label: t("quarterly"), value: "quarterly" },
                                    { label: t("annually"), value: "annually" },
                                ]}
                                theme={theme}
                                value={formik.values.frequency}
                                onChange={(event) => {
                                    dispatch(setData(null))
                                    formik.handleChange(event)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={4}>
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            <AutocompleteCustom
                                label={isInsurer(currentUser.authDetails.identityType) ? t("agents") : t("insurers")}
                                fullWidth
                                getLabel={false}
                                name="insurer"
                                options={optionsInsurer}
                                theme={theme}
                                value={optionsInsurer.find(it => it.value === formik.values.insurer)}
                                onChange={(event, value) => {
                                    dispatch(setData(null))
                                    formik.setFieldValue("insurer", value)
                                }}
                            />
                        </Grid>
                        <Grid item xs={5}>
                            <AutocompleteCustom
                                label={t("product")}
                                fullWidth
                                getLabel={false}
                                name="productId"
                                options={optionsProduct}
                                theme={theme}
                                value={optionsProduct.find(it => it.value === formik.values.productId)}
                                onChange={(event, value) => {
                                    dispatch(setData(null))
                                    formik.setFieldValue("productId", value)
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={3}>
                    <Box mt={2}>
                        <Grid container spacing={1} alignItems="flex-end" justifyContent="flex-end">
                            <Grid item>
                                <Button
                                    color="primary"
                                    disabled={!formik.values.dateFrom || !formik.values.dateTo}
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    onClick={formik.submitForm}
                                >
                                    {t("createReport")}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    fullWidth
                                    disabled={!data}
                                    variant="text"
                                    startIcon={<GetApp />}
                                    onClick={handleDownloadCSV}
                                >
                                    {t("download")}
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
            <CSVLink ref={csvLinkEl} data={csvData} filename={filename} />
        </>
    );
};
