import BaseApiClient from './BaseApiClient';

export default class ClaimApiClient extends BaseApiClient {
    constructor(...args) {
        super('/claim/api/v1/', ...args);
    }

    async search(query, limit = 500, offset = 0) {
        try {
            const config = {params: {limit, offset, ...query}};
            const response = await super.handleGet(this.basepath, config);
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }

    async createClaim(claim, files) {
        const formData = new FormData();
        formData.append('claim', JSON.stringify(claim));
        const attachments = files.images ? files.images : files;
        attachments.forEach(it => formData.append('files', it.file, it.name));
        const headers = {'content-type': 'multipart/form-data'};
        const response = await super.handlePost(this.basepath, formData, {headers});
        super.handleResponse(response);
        return response.data;
    }

    async getClaim(id) {
        const response = await super.handleGet(`${this.basepath}${id}`);
        super.handleResponse(response);
        return response.data;
    }

    async getClaimsCsv(limit = 500, offset = 0) {
        try {
            const response = await super.handleGet(`${this.basepath}?format=csv`, {params: {limit, offset}});
            super.handleResponse(response);
            return response.data;
        } catch (error) {
            console.log(error.message);
            throw error;
        }
    }
}
