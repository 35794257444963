import BaseApiClient from './BaseApiClient';

export default class AuditApiClient extends BaseApiClient {
    constructor(...args) {
        super('/audit/api/v1/', ...args);
    }

    async search(filters, sort = 'desc') {
        const config = {params: {limit: 500, offset: 0, sort, ...filters}};
        const response = await super.handleGet(`${this.basepath}search`, config);
        super.handleResponse(response);
        return response.data;
    }

    async getEventType(type = 'all') {
        const response = await super.handleGet(`${this.basepath}eventType/${type}`);
        super.handleResponse(response);
        return response.data;
    }
}
