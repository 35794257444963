import * as Sentry from '@sentry/browser';

export default function registerErrorHandlers() {
    // Catching errors or unhandled promise rejections. For some reason eventlisteners don't play well with babel/es6.promise
    window.onerror = (msg, url, lineNo, columnNo, error) => captureException(error);
    window.onunhandledrejection = e => captureException(e.reason);
}

const captureException = e => {
    console.error(e);
    Sentry.withScope(scope => {
        scope.setExtra('debug', false);
        Sentry.captureException(e);
    });
};
