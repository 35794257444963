import React from "react";
import { i18n } from "@surelync/common";
import { TableFooter } from "../components";

export const getHeaderColumnsI18 = (isMobile: boolean, footerData: Record<string, string>, classes: Record<string, string>) => [
    {
        Header: () => i18n.t("date"),
        accessor: "date",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("date")],
        },
    },
    {
        Header: () => i18n.t("cancellationsAtInception"),
        accessor: "inception",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("cancellationsAtInception")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["inception_total"]} />
    },
    {
        Header: () => i18n.t("cancellationsAtMidTerm"),
        accessor: "mid_term",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("cancellationsAtMidTerm")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["mid_term_total"]} />
    },
    {
        Header: () => i18n.t("totalNumberOfCancellations"),
        accessor: "total",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("totalNumberOfCancellations")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["total_total"]} />
    },
    {
        Header: () => i18n.t("grossPremiumAtFullTerm"),
        accessor: "gross_premium",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("grossPremiumAtFullTerm")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["gross_premium_total"]} />
    },
    {
        Header: () => i18n.t("commissionAtFullTerm"),
        accessor: "commission",
        size: isMobile ? "small" : "medium",
        toCSV: {
            headers: [i18n.t("commissionAtFullTerm")],
        },
        Footer: () => <TableFooter classes={classes} value={footerData["commission_total"]} />
    },
];
