// UNCOMMENT TS AND REPLACE JS WHEN TRANSFER blocksure-core TO TS

// @flow
// import type {ApiResponse} from './BaseApiClient';
import BaseApiClient from './BaseApiClient';

export default class PermissionApiClient extends BaseApiClient {
    constructor(...args) {
        super('/auth/api/v1/permission', ...args);
    }

    // async listPermissions<T>(): Promise<T> {
    //     const response: ApiResponse<T> = await super.handleGet(this.basepath);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async listPermissions() {
        const response = await super.handleGet(this.basepath);
        super.handleResponse(response);
        return response.data;
    }
}
