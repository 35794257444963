import BaseApiClient from './BaseApiClient';

export default class ProductApiClient extends BaseApiClient {
    constructor(...args) {
        super('/product/api/v1/', ...args);
    }

    /**
    * Returns has linked env or not.
    * @returns {Promise<*>} The JSON env.
    */
    async env() {
        const response = await super.handleGet(`${this.basepath}env`);
        super.handleResponse(response);
        return response.data;
    }

    async linked(query = { 'load.approvals': true, 'load.roles': true }, limit = 500, offset = 0) {
        const config = { params: { limit, offset, ...query } };
        const response = await super.handleGet(`${this.basepath}linked`, config);
        super.handleResponse(response);
        return response.data;
    }

    async listProducts() {
        const response = await super.handleGet(this.basepath);
        super.handleResponse(response);
        return response.data;
    }

    async createUpdateProduct(product, files) {
        const formData = new FormData();
        formData.append('product', JSON.stringify(product));
        files.forEach(it => formData.append('files', it.file, it.name));
        const headers = { 'content-type': 'multipart/form-data' };
        const response = await super.handlePost(`${this.basepath}`, formData, { headers });
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Returns existing Product plus a newly generated unique product ID.
     * @param id The ID is that of an existing product on the ledger.
     * @returns {Promise<*>} The JSON product.
     */
    async clone(id) {
        const response = await super.handleGet(`${this.basepath}clone/${id}`);
        super.handleResponse(response);
        return response.data;
    }

    async uploadRatingCalcSpreadsheet(id, ratingCalcSpreadsheet) {
        const formData = new FormData();
        formData.append('ratingCalcSpreadsheet', ratingCalcSpreadsheet.file, ratingCalcSpreadsheet.name);
        const headers = { 'content-type': 'multipart/form-data' };
        const response = await super.handlePost(`${this.basepath}${id}/rating`, formData, { headers });
        super.handleResponse(response);
        return response.data;
    }

    async search(query = { 'load.approvals': true, 'load.roles': true }, limit = 500, offset = 0) {
        const config = { params: { limit, offset, ...query } };
        const response = await super.handleGet(this.basepath, config);
        super.handleResponse(response);
        return response.data;
    }

    async searchPending(query = { 'load.approvals': true, 'load.roles': true }, limit = 500, offset = 0) {
        const config = { params: { limit, offset, ...query } };
        const response = await super.handleGet(`${this.basepath}pending`, config);
        super.handleResponse(response);
        return response.data;
    }

    async getAttachment(id, hash, pending = false, version) {
        const response = await super.handleGet(`${this.basepath}${id}/${hash}?product=${this.product}`, { params: { pending, version } });
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Returns the generic attachments.
     * @param country The country of the product.
     * @returns {files: [], attachments: {dynamicDocumentTemplates: [], hiddenAttachments: [], staticAttachments: []}, notifications: []}
     * Files Array and attachments and notifications Array.
     */
    async getGenericAttachments(country) {
        // Used 'fetch' because it has formData() method
        const response = await fetch(`${this.basepath}genericAttachments/${country}?product=${this.product}`, { method: 'GET' });
        super.handleResponse(response);
        const res = await response.formData();
        const files = [];
        const attachments = {
            dynamicDocumentTemplates: [],
            hiddenAttachments: [],
            staticAttachments: []
        };
        const notifications = {};
        const parseAttachment = (property, data) => {
            const attachment = JSON.parse(data);
            attachments[property].push(attachment);
            attachment.transactions.forEach((transaction) => {
                if (!notifications[transaction]) {
                    notifications[transaction] = {
                        document: '',
                        documentsAttached: [],
                        offsets: null,
                        relativeTo: null,
                        transaction,
                    };
                }
                if (attachment.name.endsWith('Email')) {
                    notifications[transaction].document = attachment.name;
                } else {
                    notifications[transaction].documentsAttached.push(attachment.name);
                }
            });
        };

        // Proccess Multipart HTTP response
        Array.from(res.entries()).forEach(([key, data]) => {
            switch (key) {
                case 'files':
                    files.push(data);
                    break;
                case 'DynamicDocumentTemplates':
                    parseAttachment('dynamicDocumentTemplates', data);
                    break;
                case 'HiddenAttachments':
                    parseAttachment('hiddenAttachments', data);
                    break;
                case 'StaticAttachments':
                    parseAttachment('staticAttachments', data);
                    break;
                default:
                    console.warn('Unsupported form data');
            }
        });

        return {
            attachments,
            files,
            notifications: Object.values(notifications)
        };
    }

    getAttachmentUrl(id, hash, appName) {
        return `${this.basepath}${id}/${hash}?app-name=${appName}&&product=${this.product}`;
    }

    /**
     * Returns the full product, including sensitive payment info - requires party auth.
     * @param id The ID of the product.
     * @param pending If we're looking for a pending product.
     * @param version
     * @returns {Promise<*>} The JSON product.
     */
    async getProduct(id, pending = false, version) {
        const response = await super.handleGet(`${this.basepath}${id}`, { params: { pending, version } });
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Update product.
     * @param product The product to update.
     * @param attachments Files to attach to the product.
     */
    async updateProduct(product, attachments) {
        const formData = new FormData();
        formData.append('product', JSON.stringify(product));
        attachments.forEach(it => formData.append('files', it.file, it.name));
        const headers = { 'content-type': 'multipart/form-data' };
        const response = await super.handlePost(`${this.basepath}withAttachments`, formData, { headers });
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Terminate product
     * @param id The ID of the product.
     */
    async terminateProduct(id) {
        const response = await super.handlePost(`${this.basepath}${id}/terminate`);
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Returns the changes that have been made to a product over its lifetime.
     * @param id The ID of the product.
     * @return The differences as a list of deltas.
     */
    async getProductDeltas(id) {
        const response = await super.handleGet(`${this.basepath}${id}/delta`);
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Approves/Rejects changes of an existing product.
     * @param id The id of the product to be updated.
     * @param approvalDto
     * @return The updated product with approvals.
     */
    async productAction(id, approvalDto) {
        const response = await super.handlePost(`${this.basepath}${id}/action`, approvalDto);
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Returns a product group.
     * @param tradingName The trading name of the group.
     */
    async getProductGroup(tradingName) {
        const response = await super.handleGet(`${this.basepath}group/${tradingName}`);
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Returns a list of product groups
     * @param active if left null, this will return all product groups else it will filter based on active status.
     */
    async getProductGroups(active) {
        const config = { params: { active } };
        const response = await super.handleGet(`${this.basepath}group`, config);
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Returns one of the attachments for a product.
     * @param productGroupId The id of the group.
     * @param hash The hash of the attachment.
     */
    async getProductGroupAttachment(productGroupId, hash) {
        const response = await super.handleGet(`${this.basepath}group/${productGroupId}/${hash}?product=${this.product}`);
        super.handleResponse(response);
        return response.data;
    }

    /**
     * Returns the URL only for a product group attachment.
     * @param productGroupId The id of the group.
     * @param hash The hash of the attachment.
     */
    getProductGroupAttachmentUrl(productGroupId, hash) {
        return `${this.basepath}group/${productGroupId}/${hash}?product=${this.product}`;
    }

    /**
     * Returns ZIP contains all the files that define the product: JSON, PDF, image, etc.
     * @param productId The id of the product.
     * @param version The version of the product.
     */
    getProductZipUrl(productId, version) {
        return `${this.basepath}exportZip/${productId}/${version}`;
    }

    /**
     * Upload product ZIP contains all the files that define the product: JSON, PDF, image, etc.
     * @param zipFile The zip file with the product.
     * @param name The file name of the zip file.
     */
    async importZip(zipFile, name) {
        const formData = new FormData();
        formData.append('zip', zipFile, name);
        const headers = { 'content-type': 'multipart/form-data' };
        const response = await super.handlePost(`${this.basepath}importZip`, formData, { headers });
        super.handleResponse(response);
        return response.data;
    }

    /**
     * PROD API imports the product, by reading it from Google bucket.
     * @param productId The id of the product.
     * @param version The version of the product.
     */
    async promoteFromLinkedEnv(productId, version) {
        const response = await super.handleGet(`${this.basepath}promoteFromLinkedEnv/${productId}/${version}`);
        super.handleResponse(response);
        return response.data;
    }

}
