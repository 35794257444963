import { lighten, makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    totalCol: {
        background: lighten(theme.palette.primary.main, 0.85),
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        paddingLeft: "6px",
        fontSize: 14,
        fontWeight: 500
    },
    borderedCol: {
        borderRight: "1px solid black",
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        paddingLeft: "6px"
    },
}));
