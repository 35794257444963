import * as React from "react";
import { useEffect, useReducer } from "react";
import GlobalContext from "./global-context";
import { defaultState, globalReducer, SET_CLIENT_LOGO, SET_CURRENT_USER, SET_LOCALE, SET_PRODUCTS } from "./reducers";
import { IProduct, IUser, localeService, namespacedLocalStorage, signInService } from "@surelync/common";
import * as FormatUtils from "@blocksure/blocksure-core/dist/src/utilities/FormatUtils";
import { BlobApiClient, ProductApiClient } from "@blocksure/blocksure-core/dist/src/services/api-clients";

type IProps = Record<string, unknown>;

const GlobalState: React.FC<IProps> = ({ children }) => {
    const [globalState, dispatch] = useReducer(globalReducer, defaultState);
    const blobApiClient = new BlobApiClient(namespacedLocalStorage);
    const productApiClient = React.useMemo(() => new ProductApiClient(namespacedLocalStorage), []);

    useEffect(() => {
        localeService.i18nLoader$.subscribe((value: boolean) => {
            if (!localeService.i18nReady) return;
            const { locale } = localeService;
            dispatch({ type: SET_LOCALE, payload: locale });
            FormatUtils.setGlobalLocale(locale);
        });

        const currentUser: IUser = {
            authDetails: signInService.loadAuthDetails(),
            userDetails: signInService.loadUserDetails(),
        };

        dispatch({
            type: SET_CURRENT_USER,
            payload: currentUser,
        });
    }, []);

    useEffect(() => {
        let isMount = true;

        try {
            const getClientLogo = async () => {
                const blob = await blobApiClient.retrievedTagGlobal("client-logo");
                if (!isMount) return;
                dispatch({ type: SET_CLIENT_LOGO, payload: blob });
            };

            getClientLogo();
        } catch (error) {
            // console.error(error);
        }

        return () => {
            isMount = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const loadProduct = { "load.approvals": true, "load.attachments": true, "load.roles": true, "load.payment": true };
                const response = await productApiClient.search(loadProduct);
                const products = response.items.reduce((cache, product: IProduct) => {
                    cache[product.id] = product;
                    return cache;
                }, {});
                dispatch({ type: SET_PRODUCTS, payload: products });
            } catch (error) {
                console.warn("error", error.message);
            }
        };

        fetchData();
    }, [productApiClient]);

    const isI18Ready = globalState.locale;

    return (
        <GlobalContext.Provider
            value={{
                ...globalState,
                namespacedLocalStorage,
                dispatchGlobal: dispatch,
            }}
        >
            {isI18Ready && children}
        </GlobalContext.Provider>
    );
};

export default GlobalState;
