import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
import GlobalContext from "../../context/global-context";
import { EIdentityType, namespacedLocalStorage } from "@surelync/common";

interface IProps {
    component: any;
    exact?: boolean;
    path: string;
    identityTypes: EIdentityType[];
}

const PrivateRoute: React.FC<IProps> = ({ component: Component, identityTypes, ...rest }) => {
    const { currentUser } = useContext(GlobalContext);

    return (
        <Route
            {...rest}
            render={(props) => {
                if (!currentUser || !currentUser.authDetails.signedIn) {
                    window.console.warn("not logged in");
                    // not logged in so redirect to login page with the return url
                    namespacedLocalStorage.setItem("redirect", props.location.pathname);
                    // click on the button in the reset password email
                    // but not set new password
                    localStorage.removeItem("token");
                    return <Redirect to={{ pathname: "/login" }} />;
                }
                // check if route is restricted by role
                if (identityTypes && identityTypes.indexOf(currentUser.authDetails.identityType) === -1) {
                    window.console.warn("role not authorised");
                    // role not authorised so redirect to home page
                    return <Redirect to={{ pathname: "/" }} />;
                }
                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
