import BaseApiClient from './BaseApiClient';

export default class AuthApiClient extends BaseApiClient {
    constructor(...args) {
        super('/auth/api/v1/', ...args);
    }

    async listSamlProviders() {
        const response = await super.handleGet(`${this.basepath}saml/providers`);
        super.handleResponse(response);
        return response.data;
    }

    async exists(email) {
        const param = encodeURIComponent(email);
        const exists = await super.handleGet(`${this.basepath}${param}/exists`);
        super.handleResponse(exists);
        return exists.data;
    }

    async tokenViaBasicAuth(username, password, headers = {}) {
        const auth = {username, password};
        this.clearJwtToken();
        return this.getToken({auth, headers});
    }

    /**
     * Retrieves a Jwt token. Setting transient to `true` means a transient token is used
     * i.e. one that cannot be refreshed
     */
    async getToken(config) {
        // avoidReauthentication param is meant to avoid going to an infinite loop by trying to refresh the token
        config.headers = {...config.headers, avoidReauthentication: true};
        const response = await super.handleGet(`${this.basepath}token`, config);
        super.handleResponse(response);
        return response.data.token;
    }

    getTransientToken() {
        return this.getToken({params: {transient: true}});
    }

    async whoAmI() {
        const response = await super.handleGet(`${this.basepath}whoami`);
        super.handleResponse(response);
        return response.data;
    }

    async getUser(emailOrUserId) {
        const param = encodeURIComponent(emailOrUserId);
        const response = await super.handleGet(`${this.basepath}user/${param}`);
        super.handleResponse(response);
        return response;
    }
}
