// UNCOMMENT TS AND REPLACE JS WHEN TRANSFER blocksure-core TO TS

// @flow
// import type {ApiResponse} from './BaseApiClient';
import BaseApiClient from './BaseApiClient';

// type UpdateRole = CreateRole

// type CreateRole = {
//     +name: string,
//     +category: string,
//     +description: string,
//     +modifiedBy: string,
// }
export default class RoleApiClient extends BaseApiClient {
    constructor(...args) {
        super('/auth/api/v1/role', ...args);
    }

    // async createRole<T>(user: CreateRole): Promise<T> {
    //     const response: ApiResponse<T> = await super.handlePost(this.basepath, user);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async createRole(user) {
        const response = await super.handlePost(this.basepath, user);
        super.handleResponse(response);
        return response.data;
    }

    // async updateRole<T>(id: number, roleUpdate: UpdateRole): Promise<T> {
    //     roleUpdate.permissions = roleUpdate.permissions || [];
    //     const response: ApiResponse<T> = await super.handlePost(`${this.basepath}/${id}`, roleUpdate);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async updateRole(id, roleUpdate) {
        roleUpdate.permissions = roleUpdate.permissions || [];
        const response = await super.handlePost(`${this.basepath}/${id}`, roleUpdate);
        super.handleResponse(response);
        return response.data;
    }

    // async listRoles<T>(): Promise<T> {
    //     const response: ApiResponse<T> = await super.handleGet(this.basepath);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async listRoles() {
        const response = await super.handleGet(this.basepath);
        super.handleResponse(response);
        return response.data;
    }

    // async getRole<T>(id: number): Promise<T> {
    //     const response: ApiResponse<T> = await super.handleGet(`${this.basepath}/${id}`);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async getRole(id) {
        const response = await super.handleGet(`${this.basepath}/${id}`);
        super.handleResponse(response);
        return response.data;
    }

    // async deleteRole<T>(id: number): Promise<T> {
    //     const response: ApiResponse<T> = await this.axios.delete(`${this.basepath}/${id}`);
    //     super.handleResponse(response);
    //     return response.data;
    // }

    async deleteRole(id) {
        const response = await this.axios.delete(`${this.basepath}/${id}`);
        super.handleResponse(response);
        return response.data;
    }
}
